import Paragraph from "src/components/Paragraph";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import { Helmet } from "react-helmet";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import ImageNavList from "src/components/ImageNavList";
import Box from "@mui/material/Box";
import Layout from "src/components/Layout";
import SubHeader from "src/components/SubHeader";
import img from "src/images/what-we-do/what-we-do-bg.jpg";

export default function WhatWeDoPage() {
  return (
    <Layout>
      <Helmet>
        <title>What We Do</title>
      </Helmet>
      <ImageHeaderUnit
        SubHeader="What We Do"
        title="Here’s How We’re Making an Impact"
        img={img}
        maxTitleWidth={450}
        backgroundPosition="50% 25%"
      />
      <Container>
        <Paragraph>
          At EOCP,{" "}
          <b>
            we build personalized, trusting, and authentic relationships with
            people experiencing homelessness, and walk alongside them as they
            grow into a life of self-reliance.
          </b>{" "}
          We know that each individual and family experiences homelessness for
          unique reasons — so we focus on genuinely understanding a person or a
          family for who they are, not what happened to them.
        </Paragraph>
        <Paragraph>
          We care for individuals and families experiencing homelessness in
          Oakland and Alameda County through{" "}
          <Link to="/residential-services">residential programs</Link> and
          intentional{" "}
          <Link to="/community-based-services">
            community-based initiatives
          </Link>{" "}
          that encourage rapid re-housing. Our offerings support people with
          specific needs, such as Veterans, formerly incarcerated individuals,
          and young adults.
        </Paragraph>
        <Paragraph>
          <b>
            Our commitment to a personalized, compassionate, and dignified
            approach is rooted in healing our community — because we know that
            “one size fits all” programs only perpetuate racism and poverty.
          </b>
        </Paragraph>
      </Container>
      <Box sx={{ backgroundColor: "green.50" }} py={4} mt={4} mb={2}>
        <Container>
          <SubHeader>Learn more about our approach and our programs</SubHeader>
          <ImageNavList
            links={[
              "/why-we-do-this-work",
              "/veteran-services",
              "/residential-services",
              "/community-based-services",
            ]}
          />
        </Container>
      </Box>
    </Layout>
  );
}
